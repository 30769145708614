<template>

<com-list api="links" :block="block" v-on:loaded="onListLoaded">

	<div class="links" v-if="block.layout === $constants.linksLayout.list">

		<div class="links-item" v-for="(item, index) in list" :key="index">

			<router-link :to="$link(buildUrl(item))">{{ item.name }}</router-link>

		</div>

	</div>

	<div class="links is-cards" v-if="block.layout === $constants.linksLayout.cards">

		<router-link :to="$link(buildUrl(item))" class="links-card" v-for="(item, index) in list" :key="index">

			<div class="links-card-image" v-bgimage="item.image" />

			<div class="links-card-name">{{ item.name }}</div>

		</router-link>

	</div>

</com-list>

</template>

<script>

import mixBlock from './mixin.js'

export default {

	mixins: [mixBlock],

	components: {
		'com-list': () => import('./common/List')
	},

	methods: {

		buildUrl: function(item) {

			var root = this.$route.path

			root = root.replace(this.$store.getters['language'].code.toLowerCase() + '/', '')
			root = root.replace(this.$store.getters['context'].url.toLowerCase() + '/', '')

			return (this.$_.contains([
				this.$constants.pageTypeIndex.product,
				this.$constants.pageTypeIndex.news,
				this.$constants.pageTypeIndex.event,
				this.$constants.pageTypeIndex.treatment,
				this.$constants.pageTypeIndex.symptom,
				this.$constants.pageTypeIndex.condition,
			], item.type)) ? root + '/' + item.url : '/' + item.url

		}

	}

}

</script>

<style scoped>

.links {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px;
	width: 100%;
}

.is-mobile .links {
	grid-template-columns: 1fr;
	grid-gap: 0px;
}

.is-mobile .links.is-cards {
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px;
}

.links-item {
	font-size: 16px;
	line-height: 20px;
	padding: 4px 0px;
	color: #1277d4;
}

.is-mobile .links-item {
	font-size: 14px;
}

.links-item:hover {
	text-decoration: underline;
}

.links-card-image {
	width: 100%;
	height: 218px;
	border-radius: 13px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

.is-mobile .links-card-image {
	height: 98px;
}

.links-card-name {
	font-size: 24px;
	color: #1a234c;
	line-height: 30px;
	padding: 10px 0px;
}

.is-mobile .links-card-name {
	font-size: 16px;
	line-height: 24px;
}

</style>
